import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";

function SelectAllControls({ setSelectedItems, items, disabled }) {
    const handleSelectAll = () => setSelectedItems(items.map((item) => item.id));
    const handleDeselectAll = () => setSelectedItems([]);

    return (
        <Grid container spacing={1} alignItems="center" style={{ marginBottom: "10px" }}>
            <Grid item>
                <Button color="primary" size="small" onClick={handleSelectAll} disabled={disabled}>
                    Select All
                </Button>
            </Grid>
            <Grid item>
                <Typography variant="body2" color="primary">
                    /
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    color="primary"
                    size="small"
                    onClick={handleDeselectAll}
                    disabled={disabled}
                >
                    Deselect All
                </Button>
            </Grid>
        </Grid>
    );
}

export default SelectAllControls;
