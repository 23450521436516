import useSWR from "swr";
import { getUser } from "helpers";
import DealerService from "services/DealerService";
import UserService from "services/UserService";

const FILTER_DEALERS = 1;

const fetchDealers = async (dealerUserId, sdlid, dlid) => {
    let managedDomains = "";

    if (dealerUserId) {
        const userResponse = await UserService.get(dealerUserId);

        if (userResponse?.data?.managedDomains) {
            try {
                const parsed = JSON.parse(userResponse.data.managedDomains);
                if (Array.isArray(parsed)) {
                    managedDomains = parsed.join(",");
                }
            } catch (err) {
                console.error("Error parsing managedDomains:", err);
            }
        }
    }

    const parsedDlid = sdlid === FILTER_DEALERS ? dlid : "";
    const dealerResponse = await DealerService.getAll(parsedDlid, managedDomains);
    return dealerResponse.data;
};

const useDealers = () => {
    const sdlid = parseInt(localStorage.getItem("_sdlid"));
    const dlid = parseInt(localStorage.getItem("_dlid"));
    const { id: dealerUserId } = getUser(sdlid) || {};

    const shouldFetch = !!dealerUserId;

    const { data: dealers, error, isLoading } = useSWR(
        shouldFetch ? ["dealers", dealerUserId, sdlid, dlid] : null,
        () => fetchDealers(dealerUserId, sdlid, dlid),
        { revalidateOnFocus: false }
    );

    return {
        dealers: dealers || [],
        isLoading,
        isError: !!error,
    };
};

export default useDealers;
