import { useEffect, useState } from "react";
import useDealersSwr from "./useDealersSwr";
import useCustomers from "./useCustomers";
import useOrderStatus from "./useOrderStatus";
import moment from "moment";
import useUser from "./useUser";
import useDealerId from "./useDealerId";

const useOrderFilters = (onChangeCallback = () => {}) => {
    const [user] = useUser();
    const { dealers = [], isLoading: dealersIsLoading } = useDealersSwr();
    const adminDealerId = useDealerId();
    const [dealerId, setDealerId] = useState(0);
    const [customerId, setCustomerId] = useState(0);
    const [fromDate, setFromDate] = useState(moment().subtract(7, "days").format("MM/DD/YYYY"));
    const [toDate, setToDate] = useState(moment().format("MM/DD/YYYY"));
    const customers = useCustomers(user?.isAdmin === 1 ? dealerId : adminDealerId);
    const status = useOrderStatus();
    const [statusId, setStatusId] = useState(0);
    const [search, setSearch] = useState("");

    useEffect(() => {
        onChangeCallback(
            user?.isAdmin === 1 ? "" : adminDealerId,
            customerId === 0 ? "" : customerId,
            fromDate,
            toDate,
            statusId === 0 ? "" : statusId,
            search
        );

        return () => {};
    }, [dealerId, customerId, fromDate, toDate, statusId, search]);

    return {
        dealers,
        dealersIsLoading,
        dealerId,
        setDealerId,
        customers,
        customerId,
        setCustomerId,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        status,
        statusId,
        setStatusId,
        search,
        setSearch,
    };
};

export default useOrderFilters;
