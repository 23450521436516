import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { IconButton, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const CustomAlert = ({ title, children, severity = "error", onClose }) => {
    return (
        <Alert
            severity={severity}
            action={
                <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
            style={{ position: "relative" }}
        >
            <AlertTitle>{title}</AlertTitle>
            <Box display="inline">{children}</Box>
        </Alert>
    );
};

export default CustomAlert;
