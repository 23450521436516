import React, { useState, useEffect, useRef } from "react";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import UserService from "services/UserService";
import DealerService from "services/DealerService";
import { getUser } from "helpers";

const FOR_A_SPECIFIC_DEALER = "1";

export default function SelectDealer() {
    const [dealers, setDealers] = useState([]);
    const [dlid, setDlid] = useState(0);
    const [isMultiDomain, setIsMultiDomain] = useState(false);
    const _dlid = localStorage.getItem("_dlid");
    const _sdlid = localStorage.getItem("_sdlid");
    const { id: dealerUserId } = getUser(_sdlid) || {};

    useEffect(() => {
        const getDealers = async () => {
            try {
                let managedDomains = "";

                if (dealerUserId) {
                    const response = await UserService.get(dealerUserId);

                    if (response.data?.managedDomains) {
                        try {
                            managedDomains = JSON.parse(response.data.managedDomains);
                            setIsMultiDomain(true);
                        } catch (error) {
                            console.error("Error parsing managedDomains:", error);
                            managedDomains = [];
                        }
                    }
                }

                if (Array.isArray(managedDomains)) {
                    managedDomains = managedDomains.join(",");
                }

                const dealers = await DealerService.getAll("", managedDomains).then((o) => o.data);
                setDealers(dealers);
            } catch (error) {
                console.error("Error fetching dealer user or dealers:", error);
            }
        };

        getDealers();
    }, [dealerUserId]);

    useEffect(() => {
        if (_dlid) setDlid(_dlid);
    }, [_dlid]);

    const handleChange = (e) => {
        setDlid(e.target.value);
        localStorage.setItem("_dlid", e.target.value);
        location.reload();
    };

    if (_sdlid === FOR_A_SPECIFIC_DEALER && !isMultiDomain) return null;

    return (
        <FormControl variant="filled" style={{ width: 250 }}>
            <InputLabel id="demo-simple-select-label">Dealers</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dlid}
                onChange={handleChange}
                label="Dealers"
            >
                <MenuItem value={0}>Select dealer</MenuItem>
                {dealers.map((dealer) => (
                    <MenuItem key={dealer.id} value={dealer.id}>
                        {dealer.company}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
