import http from "./Http";

const getAll = async () => {
    const res = await http.get("/pages");
    return res.data;
};

const PageService = {
    getAll,
};

export default PageService;
