import React, { useState } from "react";
import { Typography, Checkbox, FormControlLabel, Box, Switch } from "@material-ui/core";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";
import { useBackdrop } from "hooks/useBackdrop";
import { useSnackbar } from "hooks/useSnackbar";
import VhostsService from "services/VhostsService";

export default function DealerToggleSwitch({ formik }) {
    const { openDialog } = useConfirmationDialog();
    const { openBackdrop, closeBackdrop } = useBackdrop();
    const openSnackbar = useSnackbar();

    const handleToggle = (event) => {
        const updatedStatus = event.target.checked ? 1 : 0;
        let manageVhost = true;

        openDialog({
            customDialog: true,
            showActions: true,
            title: (
                <Typography gutterBottom variant="inherit" style={{ fontSize: 16 }}>
                    {updatedStatus
                        ? "Are you sure you want to enable this dealer's store?"
                        : "Are you sure you want to disable this dealer's store?"}
                </Typography>
            ),
            message: (
                <DialogContent
                    updatedStatus={updatedStatus}
                    manageVhost={manageVhost}
                    onUpdate={(value) => (manageVhost = value)}
                />
            ),
            onConfirm: async () => {
                openBackdrop();

                try {
                    const response = await VhostsService.vhostToggle(
                        formik.values.id,
                        updatedStatus,
                        manageVhost
                    );

                    formik.setFieldValue("isActive", updatedStatus);

                    openSnackbar(
                        "success",
                        updatedStatus === 1
                            ? "Dealer has been marked as active."
                            : "Dealer has been marked as inactive."
                    );

                    window.location.reload();
                } catch (error) {
                    console.error("Error updating VHost:", error);
                    openSnackbar("error", "An error occurred while updating the dealer status.");
                } finally {
                    closeBackdrop();
                }
            },
        });
    };

    return (
        <>
            {/* Left Label */}
            <Typography style={{ marginRight: 8 }}>Inactive</Typography>

            {/* Switch */}
            <Switch
                id="isActive"
                name="isActive"
                checked={formik.values.isActive === 1}
                onChange={handleToggle}
                color="primary"
            />

            {/* Right Label */}
            <Typography style={{ marginLeft: 8 }}>Active</Typography>
        </>
    );
}

function DialogContent({ updatedStatus, manageVhost, onUpdate }) {
    const [checked, setChecked] = useState(manageVhost);

    return (
        <>
            {/* Mensaje principal dinámico */}
            <Typography variant="body2" color="primary">
                {updatedStatus
                    ? "The virtual host will be enabled, linking the store domain to the server."
                    : "The virtual host will be disabled, preventing access to the store."}
            </Typography>

            {/* Explicación secundaria */}
            <Typography variant="caption" color="textSecondary">
                {updatedStatus
                    ? "Enabling the store will restore the virtual host, allowing customers to access the site."
                    : "Disabling the store will deactivate the virtual host, making the site inaccessible, but keeping the configuration intact."}
            </Typography>

            {/* Checkbox dinámico */}
            <Box mt={2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={(e) => {
                                setChecked(e.target.checked);
                                onUpdate(e.target.checked);
                            }}
                            color="primary"
                        />
                    }
                    label={updatedStatus ? "Enable Virtual Host" : "Disable Virtual Host"}
                    style={{
                        color: "#000",
                    }}
                />
            </Box>
        </>
    );
}
