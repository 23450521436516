import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    FormControlLabel,
    Checkbox,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import SelectAllControls from "./SelectAllControls";
import VehicleService from "services/VehicleService";
import useDealerId from "hooks/useDealerId";

function MakesSelection({
    selectedCatalogs = [],
    selectedRegion = "USA",
    makesByCatalog = {},
    setMakesByCatalog = () => {},
    selectedMakes = [],
    setSelectedMakes = () => {},
}) {
    const dealerId = useDealerId();
    const [loadingCatalogs, setLoadingCatalogs] = useState({});

    useEffect(() => {
        const fetchMakesForNewCatalogs = async () => {
            if (selectedCatalogs.length === 0) return;

            selectedCatalogs.forEach(async (catalog) => {
                if (makesByCatalog[catalog]) return;

                setLoadingCatalogs((prev) => ({ ...prev, [catalog]: true }));

                try {
                    const makes = await VehicleService.getMakes({
                        dlid: dealerId,
                        catalog,
                        region: selectedRegion,
                    });

                    setMakesByCatalog((prevMakes) => ({
                        ...prevMakes,
                        [catalog]: makes,
                    }));
                } catch (error) {
                    console.error(`Error obteniendo marcas para catálogo ${catalog}:`, error);
                } finally {
                    setLoadingCatalogs((prev) => ({ ...prev, [catalog]: false }));
                }
            });
        };

        fetchMakesForNewCatalogs();
    }, [dealerId, selectedCatalogs, selectedRegion]);

    const allMakes = Array.from(
        new Map(
            selectedCatalogs
                .flatMap((catalog) => makesByCatalog[catalog] || [])
                .map((make) => [make.name, make])
        ).values()
    ).sort((a, b) => a.name.localeCompare(b.name));

    const handleToggle = (id) => {
        setSelectedMakes(
            selectedMakes.includes(id)
                ? selectedMakes.filter((item) => item !== id)
                : [...selectedMakes, id]
        );
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Select Makes
                </Typography>
                <SelectAllControls
                    setSelectedItems={setSelectedMakes}
                    items={allMakes}
                    disabled={selectedCatalogs.length === 0}
                />
                {Object.values(loadingCatalogs).some((isLoading) => isLoading) && (
                    <div style={{ textAlign: "center", margin: "10px 0" }}>
                        <CircularProgress size={24} />
                        <Typography variant="body2">Loading makes...</Typography>
                    </div>
                )}
                <Grid container spacing={2}>
                    {selectedCatalogs.length > 0 &&
                        allMakes.map((make) => (
                            <Grid
                                item
                                xs={4}
                                key={make.id}
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedMakes.includes(make.id)}
                                            onChange={() => handleToggle(make.id)}
                                            color="primary"
                                        />
                                    }
                                    label={make.name}
                                />
                            </Grid>
                        ))}
                </Grid>
            </CardContent>
        </Card>
    );
}

export default MakesSelection;
