import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    FormControlLabel,
    Checkbox,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import SelectAllControls from "./SelectAllControls";
import VehicleService from "services/VehicleService";
import useDealerId from "hooks/useDealerId";

function YearsSelection({
    selectedCatalogs = [],
    selectedRegion = "USA",
    selectedMakes = [],
    selectedYears = [],
    setSelectedYears = () => {},
}) {
    const dealerId = useDealerId();
    const [yearsByMakeCatalog, setYearsByMakeCatalog] = useState({});
    const [loadingCombinations, setLoadingCombinations] = useState({});

    useEffect(() => {
        const fetchYearsForNewCombinations = async () => {
            if (selectedMakes.length === 0 || selectedCatalogs.length === 0) return;

            selectedCatalogs.forEach((catalog) => {
                selectedMakes.forEach(async (makeId) => {
                    const key = `${makeId}-${catalog}`;
                    if (yearsByMakeCatalog[key]) return;

                    setLoadingCombinations((prev) => ({ ...prev, [key]: true }));

                    try {
                        const years = await VehicleService.getYears({
                            dlid: dealerId,
                            makeId,
                            catalog,
                            region: selectedRegion,
                        });

                        setYearsByMakeCatalog((prevYears) => ({
                            ...prevYears,
                            [key]: years,
                        }));
                    } catch (error) {
                        console.error(
                            `Error obteniendo años para makeId ${makeId} y catálogo ${catalog}:`,
                            error
                        );
                    } finally {
                        setLoadingCombinations((prev) => ({ ...prev, [key]: false }));
                    }
                });
            });
        };

        fetchYearsForNewCombinations();
    }, [dealerId, selectedCatalogs, selectedRegion, selectedMakes]);

    const allYears = Array.from(
        new Set(
            selectedMakes
                .flatMap((makeId) =>
                    selectedCatalogs.flatMap(
                        (catalog) => yearsByMakeCatalog[`${makeId}-${catalog}`] || []
                    )
                )
                .map((year) => year.name)
        )
    )
        .map((name) => ({ id: name, name }))
        .sort((a, b) => parseInt(b.name) - parseInt(a.name));

    const handleToggle = (id) => {
        setSelectedYears(
            selectedYears.includes(id)
                ? selectedYears.filter((item) => item !== id)
                : [...selectedYears, id]
        );
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Select Years
                </Typography>
                <SelectAllControls
                    setSelectedItems={setSelectedYears}
                    items={allYears}
                    disabled={selectedMakes.length === 0 || selectedCatalogs.length === 0}
                />
                {Object.values(loadingCombinations).some((isLoading) => isLoading) && (
                    <div style={{ textAlign: "center", margin: "10px 0" }}>
                        <CircularProgress size={24} />
                        <Typography variant="body2">Loading years...</Typography>
                    </div>
                )}
                <Grid container spacing={2}>
                    {selectedMakes.length > 0 &&
                        allYears.map((year) => (
                            <Grid
                                item
                                xs={4}
                                key={year.id}
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedYears.includes(year.id)}
                                            onChange={() => handleToggle(year.id)}
                                            color="primary"
                                        />
                                    }
                                    label={year.name}
                                />
                            </Grid>
                        ))}
                </Grid>
            </CardContent>
        </Card>
    );
}

export default YearsSelection;
