import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
} from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// const
import { CREATE_ROLE, EDIT_ROLE } from "./Roles";
import useMenu from "../../hooks/useMenu";

//services
import RoleService from "../../services/RoleService";
import MenuService from "../../services/MenuService";
import useDealer from "hooks/useDealer";
import useDealers from "hooks/useDealers";

const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
});

const useStyles = makeStyles(styles);

const useViewStyles = makeStyles({
    root: {},
});

const useItemStyles = makeStyles((theme) => ({
    root: {
        "& > .MuiTreeItem-content > .MuiTreeItem-label": {
            display: "flex",
            alignItems: "center",
            padding: "4px 0",
            background: "transparent !important",
            pointerEvents: "none",
        },
        "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
            content: "''",
            display: "inline-block",
            width: 12,
            height: 12,
            marginRight: 8,
            border: "1px solid #ccc",
            background: "white",
        },
    },
    iconContainer: {
        marginRight: 12,
        "& > svg": {
            //padding: 8,
            padding: 1,
            "&:hover": {
                opacity: 0.6,
            },
        },
    },
    label: {
        padding: 0,
    },
    selected: {
        "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
            background: theme.palette.primary.main,
            border: "1px solid transparent",
        },
    },
}));

export default function CreateRole({ form = CREATE_ROLE, initialValues, onSuccess }) {
    const classesView = useViewStyles();
    const classesItem = useItemStyles();
    const { arrMenu = [], treeMenu = [] } = useMenu();
    const [expanded, setExpanded] = useState([]);
    const dealers = useDealers();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || 0,
            dealerId: initialValues.dealerId || "",
            name: initialValues.name || "",
            menuOptions: initialValues.menuOptions || [],
            roleType: initialValues.roleType || "smartdealer",
            isMultiDomain: initialValues.isMultiDomain || false,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (form === CREATE_ROLE) createRole(values);
            if (form === EDIT_ROLE) updateRole(values);
        },
    });

    const createRole = async (body) => {
        try {
            const role = await RoleService.post(body);
            onSuccess(role);
        } catch (error) {
            console.error(error);
        }
    };

    const updateRole = async (body) => {
        try {
            const role = await RoleService.put(body);
            onSuccess(role);
        } catch (error) {
            console.error(error);
        }
    };

    const handleToggle = (event, nodeIds) => {
        if (event.target.nodeName !== "svg") {
            return;
        }
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        if (event.target.nodeName === "svg") {
            return;
        }

        const first = nodeIds[0];
        const currMenu = arrMenu.find((m) => m.id == first);
        const childrenId = arrMenu
            .filter((m) => m.parentMenuId === currMenu.id)
            .map((m) => m.id.toString());

        if (formik.values.menuOptions.includes(first)) {
            formik.setFieldValue(
                "menuOptions",
                formik.values.menuOptions.filter((m) => ![first, ...childrenId].includes(m))
            );
        } else {
            formik.setFieldValue("menuOptions", [
                ...formik.values.menuOptions,
                first,
                ...childrenId,
            ]);
        }
    };

    const handleRoleChange = (event) => {
        formik.setFieldValue("roleType", event.target.value);
    };

    function createLinks(menu = []) {
        return menu.map((submenu, index) => (
            <TreeItem
                key={submenu.id}
                classes={classesItem}
                nodeId={submenu.id.toString()}
                label={submenu.name}
            >
                {submenu.views.length > 0 ? createLinks(submenu.views) : null}
            </TreeItem>
        ));
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ display: "none" }}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                style={{ marginTop: 5, marginBottom: 15 }}
                            >
                                <InputLabel id="demo-simple-select-label">Dealer</InputLabel>
                                <Select
                                    id="dealerId"
                                    name="dealerId"
                                    value={formik.values.dealerId}
                                    onChange={(e) => {
                                        formik.setFieldValue("dealerId", e.target.value);
                                    }}
                                    fullWidth
                                >
                                    <MenuItem key={0} value={0}>
                                        Select dealers
                                    </MenuItem>
                                    {dealers.map(({ id, company = "" }, index) => (
                                        <MenuItem key={id} value={id}>
                                            {company}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" style={{ marginBottom: 0 }}>
                                    The role to be created will be available for
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="role"
                                    name="role"
                                    value={formik.values.roleType}
                                    onChange={handleRoleChange}
                                >
                                    <FormControlLabel
                                        value="smartdealer"
                                        control={<Radio />}
                                        label="Smartdealer"
                                    />
                                    <FormControlLabel
                                        value="dealer"
                                        control={<Radio />}
                                        label="Dealer"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="name"
                                name="name"
                                label="Role name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <Typography
                                variant="h4"
                                gutterBottom
                                style={{ fontSize: 20, marginTop: 5 }}
                            >
                                Menu options
                            </Typography>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={21} md={12}>
                            <TreeView
                                classes={classesView}
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                expanded={expanded}
                                selected={formik.values.menuOptions}
                                onNodeToggle={handleToggle}
                                onNodeSelect={handleSelect}
                                multiSelect
                            >
                                {/*<TreeItem classes={classesItem} nodeId="1" label={"nivel 1"}>
                                    <TreeItem classes={classesItem} nodeId="2" label={"nivel 2"}>
                                        <TreeItem classes={classesItem} nodeId={3} label={"hijo"} />
                                    </TreeItem>
                                </TreeItem>*/}

                                {createLinks(treeMenu)}

                                {/*menu.filter(m => m.parentMenuId === 0).map((option, i) => {
                                        if (option.children.length === 0) {
                                            return <TreeItem key={option.id} classes={classesItem} nodeId={option.id.toString()} label={option.name} />
                                        } else {
                                            return (
                                                <TreeItem key={option.id} classes={classesItem} nodeId={option.id.toString()} label={option.name}>
                                                    {
                                                        option.children.map((option, i) => (
                                                            <TreeItem key={option.id} classes={classesItem} nodeId={option.id.toString()} label={option.name} />
                                                        ))
                                                    }
                                                </TreeItem>
                                            )
                                        }
                                    })*/}
                            </TreeView>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <Typography
                                variant="h4"
                                gutterBottom
                                style={{ fontSize: 20, marginTop: 5 }}
                            >
                                Role Features
                            </Typography>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="isMultiDomain"
                                        name="isMultiDomain"
                                        checked={formik.values.isMultiDomain === "Y"}
                                        onChange={(event) => {
                                            formik.setFieldValue(
                                                "isMultiDomain",
                                                event.target.checked ? "Y" : "N"
                                            );
                                        }}
                                        color="primary"
                                    />
                                }
                                label="Can manage multiple domains"
                                style={{
                                    color: "black",
                                    fontSize: 14,
                                }}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ textAlign: "center", marginTop: 5 }}
                        >
                            <Button type="submit" color="rose">
                                Save and close
                            </Button>
                        </GridItem>
                    </GridContainer>
                </form>
            </GridItem>
        </GridContainer>
    );
}
