import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import CustomAlert from "components/CustomAlert/CustomAlert";
import MissingProductsTable from "./MissingProductsTable";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";

function ExportAlert({
    alert,
    startTime,
    endTime,
    duration,
    missingSkus = [],
    minStockLevel,
    dimWeightFrom,
    dimWeightTo,
    onClose = () => {},
}) {
    const { openDialog, closeDialog } = useConfirmationDialog();

    const handleOpenDialog = () => {
        openDialog({
            customDialog: true,
            showActions: false,
            fullWidth: true,
            maxWidth: "md",
            title: (
                <Typography gutterBottom variant="inherit" style={{ fontSize: 16 }}>
                    Missing Products in Export
                </Typography>
            ),
            message: (
                <>
                    <Typography color="textSecondary" variant="body2" gutterBottom>
                        The following SKUs were not included in the export due to specific
                        conditions. Each item has an observation indicating the reason for
                        exclusion.
                    </Typography>
                    <MissingProductsTable
                        missingSkus={missingSkus}
                        minStockLevel={minStockLevel}
                        dimWeightFrom={dimWeightFrom}
                        dimWeightTo={dimWeightTo}
                    />
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button onClick={closeDialog} color="primary">
                            Close
                        </Button>
                    </Box>
                </>
            ),
        });
    };

    return (
        <Box mb={2}>
            {alert.show && startTime && endTime && duration && (
                <CustomAlert title={alert.message} severity={alert.severity} onClose={onClose}>
                    Export finished.
                    <Box component="span" fontWeight="bold">
                        {" "}
                        Start:{" "}
                    </Box>
                    {startTime.toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    })}
                    ,
                    <Box component="span" fontWeight="bold">
                        {" "}
                        End:{" "}
                    </Box>
                    {endTime.toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    })}
                    ,
                    <Box component="span" fontWeight="bold">
                        {" "}
                        Duration:{" "}
                    </Box>
                    {duration}.
                    {missingSkus.length > 0 && (
                        <Box mt={1}>
                            <Button color="primary" size="small" onClick={handleOpenDialog}>
                                View Log
                            </Button>
                        </Box>
                    )}
                </CustomAlert>
            )}
        </Box>
    );
}

export default ExportAlert;
