import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import TableWrapper from "components/TableWrapper/TableWrapper";
import ExportService from "services/ExportService";

function MissingProductsTable({ missingSkus = [], minStockLevel, dimWeightFrom, dimWeightTo }) {
    const [data, setData] = useState([]);
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const pageCount = Math.ceil(missingSkus.length / pageSize);

    const fetchData = async ({ pageIndex }) => {
        const start = (pageIndex - 1) * pageSize;
        const end = start + pageSize;
        const currentSkus = missingSkus.slice(start, end);

        try {
            const response = await ExportService.fetchInventoryObservations({
                skus: currentSkus,
                minStockLevel,
                dimWeightFrom,
                dimWeightTo,
            });

            setData(response);
            setCurrentPage(pageIndex); // Guardar la página actual
        } catch (error) {
            console.error("Error fetching inventory observations:", error);
            setData([]);
        }
    };

    return (
        <Box>
            <Typography
                color="textSecondary"
                variant="body2"
                gutterBottom
                style={{ marginTop: 20 }}
            >
                Showing {data.length} of {missingSkus.length} SKUs
            </Typography>

            <TableWrapper
                columns={[
                    {
                        Header: "#",
                        accessor: "index",
                        Cell: ({ row }) => (currentPage - 1) * pageSize + row.index + 1,
                    },
                    { Header: "Part Number", accessor: "sku" },
                    {
                        Header: "Observation(s)",
                        accessor: "observations",
                        Cell: ({ value }) =>
                            Array.isArray(value) && value.length > 1 ? (
                                <ul style={{ paddingLeft: "20px", margin: 0 }}>
                                    {value.map((obs, index) => (
                                        <li key={index}>{obs}</li>
                                    ))}
                                </ul>
                            ) : (
                                value[0] || "—"
                            ),
                    },
                    {
                        Header: "Stock",
                        accessor: "jacksonville",
                        Cell: ({ value }) => (value !== null ? value : "—"),
                    },
                    {
                        Header: "Weight",
                        accessor: "maxWeight",
                        Cell: ({ value }) => (value !== null ? value.toFixed(2) : "—"),
                    },
                ]}
                data={data}
                pageCount={pageCount}
                fetchData={fetchData}
            />
        </Box>
    );
}

export default MissingProductsTable;
