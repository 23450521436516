import http from "./Http";

// Configurar los VHosts (clonación de configuración de un dealer a otro)
const setupVHosts = async (sourceDealerId, targetDealerId) => {
    const res = await http.post(`/vhosts`, {
        sourceDealerId: sourceDealerId,
        targetDealerId: targetDealerId,
    });

    return res.data;
};

// Habilitar/Deshabilitar un dealer y gestionar VHosts si es necesario
const vhostToggle = async (dealerId, isActive, manageVhost) => {
    const res = await http.post(`/vhost-toggle`, {
        dealerId: dealerId,
        isActive: isActive,
        manageVhost: manageVhost,
    });
    return res.data;
};

const VhostsService = {
    setupVHosts,
    vhostToggle,
};

export default VhostsService;
