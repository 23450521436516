import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";

// Custom views
import Catalogs from "views/Catalogs/Catalogs.js";
import Users from "views/Users/Users.js";
import Dealers from "views/Dealers/Dealers.js";
import Translate from "views/Translate/Translate";
import GoogleShopFeed from "views/GoogleShopFeed/GoogleShopFeed";
//import ActivityLog from "views/ActivityLog/ActivityLog";
import NewMessage from "views/NewMessage/NewMessage";
import Orders from "views/OrdersNew/Orders";
import OrdersRefactor from "views/Orders/Orders";
//import Orders, { NEW_ORDERS, } from "views/Orders/Orders";
import Customers from "views/Customers/Customers";
import Roles from "views/Roles/Roles";
import DealerRoles from "views/DealerRoles/DealerRoles";
import CustomerQuotes from "views/CustomerQuotes/CustomerQuotes";
import PaymentMethods from "views/PaymentMethods/PaymentMethods";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import { getUser } from "helpers";
import DealerProfile from "views/DealerProfile/DealerProfile";
import DealerSettings from "views/DealerSettings/DealerSettings";
import DealerPricing from "views/DealerPricing/DealerPricing";
import DealerScripts from "views/DealerScripts/DealerScripts";
import DealerCatalogs from "views/DealerCatalogs/DealerCatalogs";
import DealerPayments from "views/DealerPayments/DealerPayments";
import DealerShipping from "views/DealerShipping/DealerShipping";
import ManageShippingOptions from "views/ManageShippingOptions/ManageShippingOptions";
import ActivityLog from "views/ActivityLog/ActivityLog";
import StorePolicies from "views/StorePolicies/StorePolicies";
import DealerPagetech from "views/DealerPagetech/DealerPagetech";
import DealerUsers from "views/DealerUsers/DealerUsers";
import FooterOptions from "views/FooterOptions/FooterOptions";
import DealerRedirects from "views/DealerRedirects/DealerRedirects";
import DealerShopFeed from "views/DealerShopFeed/DealerShopFeed";
import Parts from "views/Parts/Parts";
import ForgotPassword from "views/Pages/ForgotPassword";
import ResetPassword from "views/Pages/ResetPassword";
import SalesReport from "views/Reports/SalesReport";
import DealerTax from "views/DealerTax/DealerTax";
import SalesReportDetail from "views/Reports/SalesReportDetail";
import RequestForm from "views/RequestForm/RequestForm";
import CustomPage from "views/CustomPage/CustomPage";
import ThemeColorCustomizer from "views/ThemeColorCustomizer/ThemeColorCustomizer";
import AboutUs from "views/AboutUs/AboutUs";
import HomePageSpecials from "views/HomePageSpecials/HomePageSpecials";
import PopularModels from "views/PopularModels/PopularModels";
import MessageTemplate from "views/MessageTemplate/MessageTemplate";
import PickUpOptions from "views/PickUpOptions/PickUpOptions";
import BulkEditItem from "views/BulkEditItem/BulkEditItem";
import RelatedItemsSettings from "views/RelatedItemsSettings/RelatedItemsSettings";
import MessageCenter from "views/MessageCenter/MessageCenter";
import EditItem from "views/EditItem/EditItem";
import Rma from "views/Rma/Rma";
import EditPart from "views/EditPart/EditPart";
import ImageLibrary from "views/ImageLibrary/ImageLibrary";
import PartNumberUrl from "views/PartNumberUrl/PartNumberUrl";
import Coupon from "views/Coupon/Coupon";
import MarketplaceExporter from "views/MarketplaceExporter/MarketplaceExporter";

var dashRoutes = [
    {
        id: "request",
        path: "/request",
        name: "Client Request",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: RequestForm,
        layout: "/admin",
    },
    {
        id: "dashboard",
        path: "/dashboard",
        name: "Dashboard",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/admin",
    },
    /*{
    collapse: true,
    name: "Settings",
    rtlName: "الجداول",
    icon: GridOn,
    state: "settingsCollapse",
    views: [
      {
        path: "/shopping-feed",
        name: "Google Shopping Feed",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: GoogleShopFeed,
        layout: "/admin",
      },
      {
        path: "/activity-log",
        name: "Activity Log",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: ActivityLog,
        layout: "/admin",
      },
    ],
  },*/
    {
        collapse: true,
        name: "Messages",
        rtlName: "الجداول",
        icon: GridOn,
        state: "messagesCollapse",
        views: [
            {
                id: "newMessage",
                path: "/new-message",
                name: "New message",
                rtlName: "طاولات عادية",
                mini: "",
                rtlMini: "صر",
                icon: DashboardIcon,
                component: NewMessage,
                layout: "/admin",
            },
        ],
    },
    {
        collapse: true,
        name: "Orders",
        rtlName: "الجداول",
        icon: GridOn,
        state: "ordersCollapse",
        views: [
            {
                id: "orders",
                path: "/orders",
                name: "Orders",
                rtlName: "طاولات عادية",
                mini: "",
                icon: DashboardIcon,
                rtlMini: "صر",
                component: () => <Orders />,
                layout: "/admin",
            },
            {
                id: "orders",
                path: "/orders-v2",
                name: "Orders",
                rtlName: "طاولات عادية",
                mini: "",
                icon: DashboardIcon,
                rtlMini: "صر",
                component: () => <OrdersRefactor />,
                layout: "/admin",
            },
            {
                id: "customers",
                path: "/customers",
                name: "Customers",
                rtlName: "طاولات عادية",
                mini: "",
                icon: DashboardIcon,
                rtlMini: "صر",
                component: Customers,
                layout: "/admin",
            },
            {
                id: "customers",
                path: "/customer-quotes",
                name: "Customer Quotes",
                rtlName: "طاولات عادية",
                mini: "",
                icon: DashboardIcon,
                rtlMini: "صر",
                component: CustomerQuotes,
                layout: "/admin",
            },
        ],
    },
    {
        id: "catalogs",
        path: "/catalogs",
        name: "Catalogs",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: Catalogs,
        layout: "/admin",
        onlyAdmin: true,
    },
    {
        id: "",
        path: "/parts",
        name: "Create/edit part",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: Parts,
        layout: "/admin",
        onlyAdmin: true,
    },
    {
        id: "users",
        path: "/users",
        name: "Users",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: Users,
        layout: "/admin",
        onlyAdmin: true,
    },
    {
        id: "roles",
        path: "/roles",
        name: "Roles",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: Roles,
        layout: "/admin",
        onlyAdmin: true,
    },
    {
        id: "dealer-roles",
        path: "/dealer-roles",
        name: "Dealer roles",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerRoles,
        layout: "/admin",
        onlyAdmin: true,
    },
    {
        id: "paymentMethods",
        path: "/payment-methods",
        name: "Payment Methods",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: PaymentMethods,
        layout: "/admin",
        onlyAdmin: true,
    },
    {
        id: "dealers",
        path: "/dealers",
        name: getUser()?.isAdmin === 1 ? "Dealers" : "Profile",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: Dealers,
        layout: "/admin",
    },
    {
        id: "translateManager",
        path: "/translate",
        name: "Translate manager",
        rtlName: "طاولات عادية",
        icon: DashboardIcon,
        mini: "",
        rtlMini: "صر",
        component: Translate,
        layout: "/admin",
        onlyAdmin: true,
    },
    {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
    },
    {
        path: "/forgot-password",
        name: "Forgot Password",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: ForgotPassword,
        layout: "/auth",
    },
    {
        path: "/reset-password",
        name: "Reset Password",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: ResetPassword,
        layout: "/auth",
    },
    {
        path: "/dealer-profile",
        name: "Dealer profile",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerProfile,
        layout: "/admin",
    },
    {
        path: "/dealer-settings",
        name: "Dealer settings",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerSettings,
        layout: "/admin",
    },
    {
        path: "/general-settings",
        name: "General settings",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerSettings,
        layout: "/admin",
    },
    {
        path: "/footer-settings",
        name: "Footer options",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: FooterOptions,
        layout: "/admin",
    },
    {
        path: "/dealer-redirects",
        name: "Redirects",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerRedirects,
        layout: "/admin",
    },
    {
        path: "/dealer-pricing",
        name: "Price Level",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerPricing,
        layout: "/admin",
    },
    {
        path: "/dealer-scripts",
        name: "Dealer scripts",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerScripts,
        layout: "/admin",
    },
    {
        path: "/dealer-catalogs",
        name: "Dealer catalogs",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerCatalogs,
        layout: "/admin",
    },
    {
        path: "/dealer-payments",
        name: "Dealer payments",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerPayments,
        layout: "/admin",
    },
    {
        path: "/manage-shipping-options",
        name: "Manage Shipping Options",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: ManageShippingOptions,
        layout: "/admin",
    },
    {
        path: "/dealer-shipping",
        name: "Shipping Options",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: ManageShippingOptions,
        layout: "/admin",
    },
    {
        path: "/dealer-pagetech",
        name: "Dealer PageTech",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerPagetech,
        layout: "/admin",
    },
    {
        path: "/dealer-users",
        name: "Dealer users",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerUsers,
        layout: "/admin",
    },
    {
        path: "/google-shopping-feed",
        name: "Google Shopping Feed",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerShopFeed,
        layout: "/admin",
    },
    {
        path: "/activity-log",
        name: "Activity log",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: ActivityLog,
        layout: "/admin",
    },
    {
        path: "/store-policies",
        name: "Store Policies",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: StorePolicies,
        layout: "/admin",
    },
    {
        path: "/dealer-tax",
        name: "Sales tax",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: DealerTax,
        layout: "/admin",
    },
    {
        path: "/reports/report",
        name: "Report",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: SalesReport,
        layout: "/admin",
    },
    {
        path: "/reports/sales-report",
        name: "Sales report",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: SalesReportDetail,
        layout: "/admin",
    },
    {
        path: "/dealer-pages",
        name: "Custom Pages",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: CustomPage,
        layout: "/admin",
    },
    {
        path: "/site-style",
        name: "Site Style",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: ThemeColorCustomizer,
        layout: "/admin",
    },
    {
        path: "/about-us",
        name: "About Us Page",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: AboutUs,
        layout: "/admin",
    },
    {
        path: "/home-page-specials",
        name: "Home Page Specials",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: HomePageSpecials,
        layout: "/admin",
    },
    {
        path: "/popular-models",
        name: "Popular Models",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: PopularModels,
        layout: "/admin",
    },
    {
        path: "/message-template",
        name: "Message Template",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: MessageTemplate,
        layout: "/admin",
    },
    {
        path: "/pick-up-options",
        name: "Pick Up Options",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: PickUpOptions,
        layout: "/admin",
    },
    {
        path: "/bulk-items-configuration",
        name: "Bulk Edit Item",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: BulkEditItem,
        layout: "/admin",
    },
    {
        path: "/manage-related-items",
        name: "Manage Related Items",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: RelatedItemsSettings,
        layout: "/admin",
    },
    {
        path: "/message-center",
        name: "Message Center",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: MessageCenter,
        layout: "/admin",
    },
    {
        path: "/edit-item",
        name: "Edit item",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: EditItem,
        layout: "/admin",
    },
    {
        path: "/rma",
        name: "RMA",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: Rma,
        layout: "/admin",
    },
    {
        path: "/edit-part",
        name: "Edit Part",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: EditPart,
        layout: "/admin",
    },
    {
        path: "/image-library",
        name: "Image Library",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: ImageLibrary,
        layout: "/admin",
    },
    {
        path: "/part-number-url",
        name: "Part Number Url",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: PartNumberUrl,
        layout: "/admin",
    },
    {
        path: "/dealer-coupons",
        name: "Dealer Coupons",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: Coupon,
        layout: "/admin",
    },
    {
        path: "/markerplace-exporter",
        name: "Export",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: MarketplaceExporter,
        layout: "/admin",
    },
    /*{
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/pricing-page",
        name: "Pricing Page",
        rtlName: "عالتسعير",
        mini: "PP",
        rtlMini: "ع",
        component: PricingPage,
        layout: "/auth",
      },
      {
        path: "/rtl-support-page",
        name: "RTL Support",
        rtlName: "صودعم رتل",
        mini: "RS",
        rtlMini: "صو",
        component: RTLSupport,
        layout: "/rtl",
      },
      {
        path: "/timeline-page",
        name: "Timeline Page",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: TimelinePage,
        layout: "/admin",
      },
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/register-page",
        name: "Register Page",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: RegisterPage,
        layout: "/auth",
      },
      {
        path: "/lock-screen-page",
        name: "Lock Screen Page",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: LockScreenPage,
        layout: "/auth",
      },
      {
        path: "/user-page",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: UserProfile,
        layout: "/admin",
      },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/auth",
      },
    ],
  },*/
    /*{
    collapse: true,
    name: "Components",
    rtlName: "المكونات",
    icon: Apps,
    state: "componentsCollapse",
    views: [
      {
        collapse: true,
        name: "Multi Level Collapse",
        rtlName: "انهيار متعدد المستويات",
        mini: "MC",
        rtlMini: "ر",
        state: "multiCollapse",
        views: [
          {
            path: "#sample-path",
            name: "Example",
            rtlName: "مثال",
            mini: "E",
            rtlMini: "ه",
            component: () => { },
            layout: "#sample-layout",
          },
        ],
      },
      {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: GridSystem,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: Panels,
        layout: "/admin",
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        rtlName: "الحلو تنبيه",
        mini: "SA",
        rtlMini: "ومن",
        component: SweetAlert,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        rtlName: "الرموز",
        mini: "I",
        rtlMini: "و",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: Typography,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "content_paste",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: RegularForms,
        layout: "/admin",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: ExtendedForms,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: ValidationForms,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: Wizard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: GridOn,
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: ExtendedTables,
        layout: "/admin",
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: ReactTables,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    rtlName: "خرائط",
    icon: Place,
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        rtlName: "خرائط جوجل",
        mini: "GM",
        rtlMini: "زم",
        component: GoogleMaps,
        layout: "/admin",
      },
      {
        path: "/full-screen-maps",
        name: "Full Screen Map",
        rtlName: "خريطة كاملة الشاشة",
        mini: "FSM",
        rtlMini: "ووم",
        component: FullScreenMap,
        layout: "/admin",
      },
      {
        path: "/vector-maps",
        name: "Vector Map",
        rtlName: "خريطة المتجه",
        mini: "VM",
        rtlMini: "تم",
        component: VectorMap,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    rtlName: "الحاجيات",
    icon: WidgetsIcon,
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    rtlName: "الرسوم البيانية",
    icon: Timeline,
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "التقويم",
    icon: DateRange,
    component: Calendar,
    layout: "/admin",
  },*/
];
export default dashRoutes;
