import React from "react";
import { Box, FormLabel, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

function CountrySelection({ selectedRegion = "USA", setSelectRegion = () => {} }) {
    const handleCountryChange = (event) => {
        setSelectRegion(event.target.value);
    };

    return (
        <Box display="flex" flexDirection="column">
            <FormLabel
                component="legend"
                color="secondary"
                style={{ marginBottom: "0px", fontWeight: "bold" }}
            >
                Country
            </FormLabel>

            <RadioGroup row value={selectedRegion || ""} onChange={handleCountryChange}>
                <FormControlLabel value="USA" control={<Radio color="primary" />} label="USA" />
                <FormControlLabel value="CAN" control={<Radio color="primary" />} label="CAN" />
            </RadioGroup>
        </Box>
    );
}

export default CountrySelection;
