import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    FormControlLabel,
    Checkbox,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import SelectAllControls from "./SelectAllControls";
import VehicleService from "services/VehicleService";
import useDealerId from "hooks/useDealerId";

function ModelsSelection({
    selectedCatalogs = [],
    selectedRegion = "USA",
    modelsByMakeYearCatalog = {},
    setModelsByMakeYearCatalog = () => {},
    selectedMakes = [],
    selectedYears = [],
    selectedModels = [],
    setSelectedModels = () => {},
}) {
    const dealerId = useDealerId();
    const [loadingCombinations, setLoadingCombinations] = useState({});
    const BATCH_SIZE = 5;

    useEffect(() => {
        const fetchModelsInBatches = async () => {
            if (
                selectedMakes.length === 0 ||
                selectedYears.length === 0 ||
                selectedCatalogs.length === 0
            )
                return;

            let pendingRequests = [];

            selectedCatalogs.forEach((catalog) => {
                selectedMakes.forEach((makeId) => {
                    selectedYears.forEach((yearId) => {
                        const key = `${makeId}-${yearId}-${catalog}`;
                        if (modelsByMakeYearCatalog[key]) return;

                        pendingRequests.push({ makeId, yearId, catalog, key });
                    });
                });
            });

            while (pendingRequests.length > 0) {
                const batch = pendingRequests.splice(0, BATCH_SIZE);

                await Promise.all(
                    batch.map(async ({ makeId, yearId, catalog, key }) => {
                        setLoadingCombinations((prev) => ({ ...prev, [key]: true }));

                        try {
                            const models = await VehicleService.getModels({
                                dlid: dealerId,
                                makeId,
                                yearId,
                                catalog,
                                region: selectedRegion,
                            });

                            setModelsByMakeYearCatalog((prevModels) => ({
                                ...prevModels,
                                [key]: models,
                            }));
                        } catch (error) {
                            console.error(`Error obteniendo modelos para ${key}:`, error);
                        } finally {
                            setLoadingCombinations((prev) => ({ ...prev, [key]: false }));
                        }
                    })
                );
            }
        };

        fetchModelsInBatches();
    }, [dealerId, selectedCatalogs, selectedRegion, selectedMakes, selectedYears]);

    const availableModels = Array.from(
        new Map(
            selectedCatalogs
                .flatMap((catalog) =>
                    selectedMakes.flatMap((makeId) =>
                        selectedYears.flatMap(
                            (yearId) =>
                                modelsByMakeYearCatalog[`${makeId}-${yearId}-${catalog}`] || []
                        )
                    )
                )
                .map((model) => [model.slug, model])
        ).values()
    )
        .map((model) => ({ id: model.slug, name: model.name }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const handleToggle = (id) => {
        setSelectedModels(
            selectedModels.includes(id)
                ? selectedModels.filter((item) => item !== id)
                : [...selectedModels, id]
        );
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Select Models
                </Typography>
                <SelectAllControls
                    setSelectedItems={setSelectedModels}
                    items={availableModels}
                    disabled={
                        selectedMakes.length === 0 ||
                        selectedYears.length === 0 ||
                        selectedCatalogs.length === 0
                    }
                />
                {Object.values(loadingCombinations).some((isLoading) => isLoading) && (
                    <div style={{ textAlign: "center", margin: "10px 0" }}>
                        <CircularProgress size={24} />
                        <Typography variant="body2">Loading models...</Typography>
                    </div>
                )}
                <div
                    style={{
                        maxHeight: 350,
                        overflowY: availableModels.length > 25 ? "auto" : "hidden",
                        paddingTop: 5,
                        paddingRight: 10,
                    }}
                >
                    <Grid container spacing={2}>
                        {availableModels.length > 0 &&
                            availableModels.map((model) => (
                                <Grid
                                    item
                                    xs={4}
                                    key={model.id}
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedModels.includes(model.id)}
                                                onChange={() => handleToggle(model.id)}
                                                color="primary"
                                            />
                                        }
                                        label={model.name}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </div>
            </CardContent>
        </Card>
    );
}

export default ModelsSelection;
