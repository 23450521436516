import React, { useRef, useState } from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import PageLayout from "layouts/PageLayout";
import CatalogSelection from "./CatalogSelection";
import CountrySelection from "./CountrySelection";
import MakesSelection from "./MakesSelection";
import YearsSelection from "./YearsSelection";
import ModelsSelection from "./ModelsSelection";
import FilterOptions from "./FilterOptions";
import ExportAlert from "./ExportAlert";
import useTimer from "hooks/useTimer";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";

export default function MarketplaceExporter() {
    // Reference to the top of the page for smooth scrolling
    const topRef = useRef(null);

    // State for error alerts
    const [alert, setAlert] = useState({
        show: false,
        severity: "error",
        message: "",
    });

    // State for selected options and filters
    const [selectedCatalogs, setSelectedCatalogs] = useState([]);
    const [selectedRegion, setSelectRegion] = useState("USA");
    const [makesByCatalog, setMakesByCatalog] = useState({});
    const [modelsByMakeYearCatalog, setModelsByMakeYearCatalog] = useState({});
    const [selectedMakes, setSelectedMakes] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    const [missingSkus, setMissingSkus] = useState([]);

    // State for filter parameters
    const [minStockLevel, setMinStockLevel] = useState("");
    const [dimWeightFrom, setDimWeightFrom] = useState("");
    const [dimWeightTo, setDimWeightTo] = useState("");

    // Timer utility for tracking export process duration
    const { startTime, endTime, duration, start, stop, reset } = useTimer();

    const handleProcessEnd = (status, message, skus = []) => {
        setAlert({
            show: true,
            severity: status,
            message,
        });

        setMissingSkus(skus);

        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <PageLayout showSelectDealer>
            <Box display="flex" alignItems="center" gap={2} mb={2}>
                {/* Selección de Catálogo */}
                <CatalogSelection
                    selectedCatalogs={selectedCatalogs}
                    setSelectedCatalogs={setSelectedCatalogs}
                />

                {/* Divider Vertical (solo si hay un catálogo seleccionado) */}
                {selectedCatalogs.length > 0 && (
                    <Divider
                        orientation="vertical"
                        flexItem
                        style={{ marginLeft: 5, marginRight: 20 }}
                    />
                )}

                {/* Selección de País (Solo si hay catálogo seleccionado) */}
                {selectedCatalogs.length > 0 && (
                    <CountrySelection
                        selectedRegion={selectedRegion}
                        setSelectRegion={setSelectRegion}
                    />
                )}
            </Box>

            <Box ref={topRef}>
                <ExportAlert
                    alert={alert}
                    startTime={startTime}
                    endTime={endTime}
                    duration={duration}
                    missingSkus={missingSkus}
                    minStockLevel={minStockLevel}
                    dimWeightFrom={dimWeightFrom}
                    dimWeightTo={dimWeightTo}
                    onClose={() => {
                        setAlert((prev) => ({ ...prev, show: false }));
                        setMissingSkus([]);
                        reset();
                    }}
                />
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <MakesSelection
                        selectedCatalogs={selectedCatalogs}
                        selectedRegion={selectedRegion}
                        makesByCatalog={makesByCatalog}
                        setMakesByCatalog={setMakesByCatalog}
                        selectedMakes={selectedMakes}
                        setSelectedMakes={setSelectedMakes}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <YearsSelection
                        selectedCatalogs={selectedCatalogs}
                        selectedRegion={selectedRegion}
                        selectedMakes={selectedMakes}
                        selectedYears={selectedYears}
                        setSelectedYears={setSelectedYears}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <ModelsSelection
                        selectedCatalogs={selectedCatalogs}
                        selectedRegion={selectedRegion}
                        modelsByMakeYearCatalog={modelsByMakeYearCatalog}
                        setModelsByMakeYearCatalog={setModelsByMakeYearCatalog}
                        selectedMakes={selectedMakes}
                        selectedYears={selectedYears}
                        selectedModels={selectedModels}
                        setSelectedModels={setSelectedModels}
                    />
                    <FilterOptions
                        selectedCatalogs={selectedCatalogs}
                        selectedRegion={selectedRegion}
                        makesByCatalog={makesByCatalog}
                        modelsByMakeYearCatalog={modelsByMakeYearCatalog}
                        selectedMakes={selectedMakes}
                        selectedYears={selectedYears}
                        selectedModels={selectedModels}
                        startTimer={start}
                        stopTimer={stop}
                        resetTimer={reset}
                        minStockLevel={minStockLevel}
                        setMinStockLevel={setMinStockLevel}
                        dimWeightFrom={dimWeightFrom}
                        setDimWeightFrom={setDimWeightFrom}
                        dimWeightTo={dimWeightTo}
                        setDimWeightTo={setDimWeightTo}
                        onProcessEnd={handleProcessEnd}
                    />
                </Grid>
            </Grid>
        </PageLayout>
    );
}
