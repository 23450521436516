import { useState, useRef } from "react";

const formatDuration = (ms) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
};

const useTimer = () => {
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [duration, setDuration] = useState(null);
    const startTimeRef = useRef(null);

    const start = () => {
        const now = new Date();

        startTimeRef.current = now;
        setStartTime(now);
        setEndTime(null);
        setDuration(null);
    };

    const stop = () => {
        if (!startTimeRef.current) return;

        const now = new Date();
        setEndTime(now);
        setDuration(formatDuration(now - startTimeRef.current));
    };

    const reset = () => {
        startTimeRef.current = null; // 🔹 Reseteamos el ref también
        setStartTime(null);
        setEndTime(null);
        setDuration(null);
    };

    return { startTime, endTime, duration, start, stop, reset };
};

export default useTimer;
