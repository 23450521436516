import React from "react";
import { Box, FormLabel, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

function CatalogSelection({ selectedCatalogs = [], setSelectedCatalogs = () => {} }) {
    const handleCatalogChange = (event) => {
        setSelectedCatalogs([event.target.value]);
    };

    return (
        <Box display="flex" flexDirection="column">
            <FormLabel
                component="legend"
                color="secondary"
                style={{ marginBottom: "0px", fontWeight: "bold" }}
            >
                Catalog
            </FormLabel>

            {/* Agregar `row` al `RadioGroup` para que los radios estén en línea */}
            <RadioGroup row value={selectedCatalogs[0] || ""} onChange={handleCatalogChange}>
                <FormControlLabel value="motor" control={<Radio color="primary" />} label="Motor" />
                <FormControlLabel
                    value="keith"
                    control={<Radio color="primary" />}
                    label="Accesorios"
                />
            </RadioGroup>
        </Box>
    );
}

export default CatalogSelection;
