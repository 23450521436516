import http, { BASE_URL_API_ECOMMERCE } from "./Http";

const getAll = async (dlid = "", domainFilter = "") => {
    return http.get(`/dealers?dlid=${dlid}&domainFilter=${domainFilter}`);
};

const get = async (dlid = "") => {
    const res = await http.get(`${BASE_URL_API_ECOMMERCE}/dealer?dlid=${dlid}`);
    return res.data.data;
};

const post = async (body) => {
    const currentYear = new Date().getFullYear();

    return http.post("/dealers", {
        ...body,
        envyMainUser: "admin",
        password: body.password || `TempKey@${currentYear}`,
    });
};

const put = async (body) => {
    const { password, ...otherProps } = body;

    return http.put(`/dealers/${body.id}`, {
        ...otherProps,
        ...(body.password && { password: btoa(body.password) }),
    });
};

const changePassword = async (body) => {
    return http.put("/dealers/change-password", body);
};

const dealerClone = async (sourceDealerId, targetDealerId, userId) => {
    return http.post("/dealer-clone", {
        sourceDealerId: sourceDealerId,
        targetDealerId: targetDealerId,
        userId: userId,
    });
};

const getDealerDomains = async () => {
    return http.get("/dealer-domains");
};

const DealerService = {
    getAll,
    get,
    post,
    put,
    changePassword,
    dealerClone,
    getDealerDomains,
};

export default DealerService;
