import http from "./Http";

const get = async (id) => {
    try {
        const res = await http.get(`/user-roles/${id}`);
        return res.data;
    } catch (error) {
        return null;
    }
};

const post = async (body) => {
    return http.post("/user-roles", body);
};

const put = async (body) => {
    return http.put("/user-roles", body);
};

const UserRoleService = {
    get,
    post,
    put,
};

export default UserRoleService;
