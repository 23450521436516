import { useState } from "react";
import {
    TextField,
    MenuItem,
    Grid,
    Box,
    InputAdornment,
    Button,
    Typography,
    Card,
    CardContent,
} from "@material-ui/core";
import ExportService from "services/ExportService";
import { useBackdrop } from "hooks/useBackdrop";
import { BASE_URL_API } from "services/Http";
import useDealerId from "hooks/useDealerId";

const languages = [
    { id: "en", name: "English" },
    { id: "es", name: "Spanish" },
    { id: "pt", name: "Portuguese" },
    { id: "fr", name: "French" },
];

const platforms = ["MercadoLibre", "Amazon"];

function FilterOptions({
    selectedCatalogs = [],
    selectedRegion = "USA",
    makesByCatalog = {},
    modelsByMakeYearCatalog = {},
    selectedMakes = [],
    selectedYears = [],
    selectedModels = [],
    startTimer = () => {},
    stopTimer = () => {},
    resetTimer = () => {},
    minStockLevel = "",
    setMinStockLevel = () => {},
    dimWeightFrom = "",
    setDimWeightFrom = () => {},
    dimWeightTo = "",
    setDimWeightTo = () => {},
    onProcessEnd = () => {},
}) {
    const dealerId = useDealerId();
    const [language, setLanguage] = useState("es");
    const [priceFrom, setPriceFrom] = useState("");
    const [priceTo, setPriceTo] = useState("");
    const [platform, setPlatform] = useState("MercadoLibre");
    const { openBackdrop, closeBackdrop } = useBackdrop();

    const isExportDisabled = !(language && priceFrom && priceTo && platform);

    /**
     * Función principal que coordina el proceso de exportación de datos.
     * Controla el flujo de obtención de productos, validación de inventario y exportación final.
     */
    const handleExportClick = async () => {
        try {
            resetTimer();
            startTimer();
            openBackdrop("Exporting data, please wait");

            const filteredModels = getFilteredModels();

            // Paso 1: Obtener y validar productos
            const { products, skus } = await fetchProductsAndValidate(
                filteredModels,
                selectedRegion
            );

            // Paso 2: Obtener y validar inventario
            const { inventoryData, missingSkus } = await fetchInventoryAndValidate(
                skus,
                minStockLevel,
                dimWeightFrom,
                dimWeightTo
            );

            // Paso 3: Procesar y exportar datos
            await processAndExportData(products, inventoryData, minStockLevel);

            stopTimer();
            onProcessEnd("success", "The Excel file was generated successfully.", missingSkus);
        } catch (error) {
            stopTimer();
            onProcessEnd(
                error.type || "error",
                error.message || "Export failed. Please try again.",
                error.missingSkus || []
            );
        } finally {
            closeBackdrop();
        }
    };

    /**
     * Obtiene los productos desde la API de Alejandro y valida si existen.
     * @param {Array} filteredModels - Modelos filtrados seleccionados.
     * @param {string} selectedRegion - Región seleccionada.
     * @returns {Object} - Lista de productos y sus SKUs únicos.
     * @throws {Object} - Lanza una advertencia si no hay productos.
     */
    const BATCH_SIZE = 3;

    const fetchProductsAndValidate = async (filteredModels, selectedRegion) => {
        let allProducts = [];
        const pendingModels = [...filteredModels];

        while (pendingModels.length > 0) {
            const batch = pendingModels.splice(0, BATCH_SIZE);

            const results = await Promise.all(
                batch.map(async (model) => {
                    try {
                        const products = await fetchProducts([model], selectedRegion);
                        return products || [];
                    } catch (error) {
                        console.error("Error fetching products for model:", model, error);
                        return [];
                    }
                })
            );

            results.forEach((productList) => {
                allProducts.push(...productList);
            });
        }

        if (!allProducts.length) {
            throw {
                type: "warning",
                message: "No products found for the selected filters.",
                missingSkus: [],
            };
        }

        return {
            products: allProducts,
            skus: [...new Set(allProducts.map((product) => product.sku))],
        };
    };

    /**
     * Obtiene los datos de inventario y valida la diferencia entre los productos obtenidos y los encontrados en el inventario.
     * @param {Array} skus - Lista de SKUs obtenidos de la API de Alejandro.
     * @param {number} minStockLevel - Nivel mínimo de stock requerido.
     * @param {number} dimWeightFrom - Peso dimensional mínimo.
     * @param {number} dimWeightTo - Peso dimensional máximo.
     * @returns {Object} - Datos de inventario filtrados.
     * @throws {Object} - Lanza una advertencia si no hay datos en el inventario.
     */
    const fetchInventoryAndValidate = async (skus, minStockLevel, dimWeightFrom, dimWeightTo) => {
        const inventoryData = await ExportService.fetchInventoryData({
            skus,
            minStockLevel,
            dimWeightFrom,
            dimWeightTo,
        });

        if (!inventoryData || !inventoryData.length) {
            throw {
                type: "warning",
                message: "No inventory data available for the selected filters.",
                missingSkus: skus,
            };
        }

        // Extraer los part_number únicos del inventario
        const inventoryPartNumbers = new Set(inventoryData.map((item) => item.partNumber));

        // Identificar los SKU que no tienen un part_number en el inventario
        const missingSkus = skus.filter((sku) => !inventoryPartNumbers.has(sku));

        console.log("Total SKUs from products:", skus.length);
        console.log("Total part_numbers from inventory:", inventoryPartNumbers.size);
        console.log("SKUs missing from inventory:", missingSkus);

        return { inventoryData, missingSkus };
    };

    /**
     * Procesa los datos obtenidos, filtra productos y genera el archivo Excel.
     * @param {Array} products - Lista de productos obtenidos de la API de Alejandro.
     * @param {Array} inventoryData - Datos de inventario disponibles.
     * @param {number} minStockLevel - Nivel mínimo de stock requerido.
     * @throws {Object} - Lanza una advertencia si no hay productos con stock suficiente.
     */
    const processAndExportData = async (products, inventoryData, minStockLevel) => {
        let enrichedProducts = await enrichProductsWithInventory(inventoryData, products);

        // Filtrar productos sin dimensiones o peso
        enrichedProducts = enrichedProducts.filter(
            (p) =>
                p.packageGrossWeight !== null &&
                p.packageLength !== null &&
                p.packageWidth !== null &&
                p.packageHeight !== null
        );

        if (minStockLevel) {
            enrichedProducts = enrichedProducts.filter((p) => p.stock >= minStockLevel);
        }

        if (minStockLevel && enrichedProducts.length === 0) {
            throw { type: "warning", message: "No products meet the Min Stock Level requirement." };
        }

        // Ordenar por productTitle
        enrichedProducts.sort((a, b) => a.productTitle.localeCompare(b.productTitle));

        // Generar y descargar el Excel
        await generateAndDownloadExcel(enrichedProducts);
    };

    /**
     * Filtra los modelos seleccionados por marca, año y catálogo.
     * @returns {Array} Lista de modelos filtrados.
     */
    const getFilteredModels = () => {
        return Object.entries(modelsByMakeYearCatalog)
            .filter(([key]) => {
                const [makeId, year, catalog] = key.split("-");
                return (
                    selectedCatalogs.includes(catalog) &&
                    selectedMakes.includes(parseInt(makeId)) &&
                    selectedYears.includes(parseInt(year))
                );
            })
            .flatMap(([key, models]) => {
                const [makeId, year] = key.split("-");
                const makeInfo = getMakeInfo(parseInt(makeId));

                return models
                    .filter((model) => selectedModels.includes(model.slug))
                    .map((model) => ({
                        make: makeInfo,
                        year: { name: parseInt(year) },
                        model: { id: model.id, name: model.name, slug: model.slug },
                    }));
            });
    };

    /**
     * Obtiene los productos del marketplace con base en los modelos filtrados.
     * @param {Array} filteredModels - Lista de modelos filtrados.
     * @returns {Promise<Array>} Lista de productos obtenidos de la API.
     */
    const fetchProducts = async (filteredModels, region = "USA") => {
        let updatedRegion = region;

        if (selectedCatalogs.length === 1 && selectedCatalogs.includes("motor")) {
            updatedRegion = region === "USA" ? "1" : region === "CAN" ? "2" : "1";
        }

        return await ExportService.fetchMarketplaceData({
            dealerId,
            region: updatedRegion,
            catalogs: selectedCatalogs,
            loc: language,
            from: priceFrom,
            to: priceTo,
            platform: platform,
            vehicles: filteredModels,
        });
    };

    /**
     * Enriquecer los productos con información del inventario.
     * @param {Array} products - Lista de productos obtenidos del marketplace.
     * @returns {Promise<Array>} Lista de productos enriquecidos con información de inventario.
     */
    const enrichProductsWithInventory = async (inventoryData = [], products = []) => {
        return products.map((product) => {
            const inventory = inventoryData.find((inv) => inv.partNumber == product.sku) || {};

            return {
                ...product,
                stock: inventory?.jacksonville || null,
                packageGrossWeight: inventory?.weight || null,
                packageWeightUnit: "LBS",
                packageLength: inventory?.length || null,
                packageWidth: inventory?.width || null,
                packageHeight: inventory?.height || null,
                packageDimentionUnit: "IN",
            };
        });
    };

    /**
     * Genera un archivo Excel con los productos enriquecidos y lo descarga.
     * @param {Array} products - Lista de productos listos para exportación.
     * @returns {Promise<void>}
     */
    const generateAndDownloadExcel = async (products) => {
        const excelResponse = await ExportService.generateMLExcel({
            dealerId,
            products,
        });

        if (excelResponse?.status === 200) {
            const baseDownloadUrl = BASE_URL_API.replace("/api", "");
            const excelFilePath = excelResponse?.data?.filePath || "";

            window.location.href = `${baseDownloadUrl}${excelFilePath}`;

            setTimeout(async () => {
                try {
                    await ExportService.deleteMLExcel({
                        dealerId,
                        fileName: excelResponse.data.filePath.split("/").pop(),
                    });
                } catch (error) {
                    console.error("Failed to delete Excel file:", error);
                }
            }, 20000);
        }
    };

    /**
     * Obtiene la información de la marca a partir de su ID.
     * @param {number} makeId - ID de la marca a buscar.
     * @returns {Object} Información de la marca con id, name y slug.
     */
    const getMakeInfo = (makeId) => {
        const make = Object.values(makesByCatalog)
            .flat()
            .find((m) => m.id === makeId);

        return make
            ? {
                  id: make.id,
                  name: make.name,
                  slug: make.slug,
              }
            : { id: makeId, name: "Unknown", slug: "unknown" };
    };

    return (
        <Card style={{ marginTop: 10 }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Select platform
                </Typography>

                {/* Select a language */}
                <TextField
                    select
                    label="Select a language"
                    fullWidth
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    margin="normal"
                >
                    {languages.map((lang) => (
                        <MenuItem key={lang.id} value={lang.id}>
                            {lang.name}
                        </MenuItem>
                    ))}
                </TextField>

                {/* Price Range */}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Price from"
                            type="number"
                            fullWidth
                            value={priceFrom}
                            onChange={(e) => setPriceFrom(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Price to"
                            type="number"
                            fullWidth
                            value={priceTo}
                            onChange={(e) => setPriceTo(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Min Stock Level */}
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item xs={6}>
                        <TextField
                            label="Min Stock Level"
                            type="number"
                            fullWidth
                            value={minStockLevel}
                            onChange={(e) => setMinStockLevel(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">{""}</InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Dimensional Weight */}
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item xs={6}>
                        <TextField
                            label="Weight from"
                            type="number"
                            fullWidth
                            value={dimWeightFrom}
                            onChange={(e) => setDimWeightFrom(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">{""}</InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Weight to"
                            type="number"
                            fullWidth
                            value={dimWeightTo}
                            onChange={(e) => setDimWeightTo(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">{""}</InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Select Platform */}
                <TextField
                    select
                    label="Select platform"
                    fullWidth
                    value={platform}
                    onChange={(e) => setPlatform(e.target.value)}
                    margin="normal"
                >
                    {platforms.map((plat) => (
                        <MenuItem key={plat} value={plat}>
                            {plat}
                        </MenuItem>
                    ))}
                </TextField>

                <Box textAlign="center" mt={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isExportDisabled}
                        onClick={handleExportClick}
                    >
                        Export
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
}

export default FilterOptions;
