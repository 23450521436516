import React, { useEffect, useState, useRef } from "react";
import PageLayout from "layouts/PageLayout";
import {
    Box,
    Button as ButtonMUI,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import SendIcon from "@material-ui/icons/Send";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import useRma from "hooks/useRma";
import useUser from "hooks/useUser";
import useCrud from "hooks/useCrud";
import { financial } from "helpers";
import DealerService from "services/DealerService";
import CustomerService from "services/CustomerService";
import RmaService from "services/RmaService";
import { formatDate } from "helpers";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

//libs
import memoize from "fast-memoize";
import DeleteButton from "components/DeleteButton/DeleteButton";
import useOrderFilters from "hooks/useOrderFilters";

export default function Rma() {
    const classes = useStyles();
    const { rmaData = [], handleClickEdit, handleClickDelete } = useRma();
    const { updateEntity } = useCrud("rma", {}, false);
    const [user] = useUser();
    const [dlid, setDlid] = useState(0);
    const [cid, setCid] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [search, setSearch] = useState("");
    const [statusId, setStatusId] = useState("Pending");
    const [orders, setOrders] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [selected, setSelected] = useState([]);
    const [checked, setChecked] = useState(false);
    const [newStatusId, setNewStatusId] = useState(0);
    const fetchIdRef = useRef(0);
    const { dealers = [], dealersIsLoading } = useOrderFilters();
    const [dealersVerified, setDealersVerified] = useState(false);

    useEffect(() => {
        if (!dealersIsLoading) {
            if (dealers.length === 1) {
                setDlid(dealers[0]?.id);
            }
            setDealersVerified(true);
        }
    }, [dealers, dealersIsLoading]);

    useEffect(() => {
        const getCustomers = async (dlid) => {
            const customers = await CustomerService.getAll(dlid, "", 0, 10000000n).then(
                (o) => o.data
            );
            setCustomers(customers.data);
        };

        if (dealersVerified) {
            getCustomers(dlid);
        }
    }, [dealersVerified, dlid]);

    const fetchData = React.useCallback(
        memoize(
            (fromDate, toDate, statusId, search, cid, dlid) => async ({ pageSize, pageIndex }) => {
                if (!dealersVerified) return;

                const fetchId = ++fetchIdRef.current;

                if (fetchId === fetchIdRef.current) {
                    const startRow = pageSize * pageIndex;

                    const orders = await RmaService.getAll(
                        startRow,
                        pageSize,
                        fromDate ? formatDate(fromDate) : "",
                        toDate ? formatDate(toDate) : "",
                        statusId,
                        search,
                        cid,
                        dlid
                    );
                    setOrders(orders?.data || []);
                    setPageCount(Math.ceil(orders.recordsTotal / pageSize));
                }
            }
        ),
        [dealersVerified]
    );

    const columns = [
        {
            Header: () => (
                <div
                    style={{
                        textAlign: "center",
                        fontSize: 15,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography style={{ fontSize: 16 }}>Select All</Typography>
                    <Checkbox
                        tabIndex={-1}
                        checked={checked}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (checked) {
                                setSelected([]);
                            } else {
                                setSelected(orders.map((o) => o.id));
                            }
                            setChecked(!checked);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                        }}
                    />
                </div>
            ),
            accessor: "isChecked",
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <Checkbox
                                tabIndex={-1}
                                checked={selected.includes(original.id)}
                                onClick={(e) => {
                                    if (selected.includes(original.id)) {
                                        setSelected(selected.filter((s) => s != original.id));
                                    } else {
                                        setSelected([...selected, original.id]);
                                    }
                                }}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                }}
                            />
                        </div>
                    </>
                );
            },
        },
        {
            Header: "Rma Number",
            accessor: "rmaNumber",
        },
        {
            Header: "Order Number",
            accessor: "orderNumber",
        },
        {
            Header: "Name",
            accessor: "billCompany",
        },
        {
            Header: "Total",
            Cell: ({ row }) => {
                return `$${financial(row.original.price)}`;
            },
        },
        {
            Header: "Sent To",
            accessor: "shipCompany",
        },
        {
            Header: "Date",
            accessor: "rmaReqDate",
        },
        {
            Header: () => <div style={{ textAlign: "center" }}></div>,
            accessor: "actions",
            Cell: ({ row: { original } }) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <Button
                            justIcon
                            round
                            simple
                            color="warning"
                            className="edit"
                            title="Edit order products"
                            onClick={async () => {
                                handleClickEdit(original);
                            }}
                        >
                            <EditIcon />
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <PageLayout showSelectDealer={false}>
            <Box mt={5}>
                <div style={{ marginBottom: 10 }}>
                    <GridContainer>
                        {user?.isAdmin === 1 && (
                            <GridItem xs={12} sm={12} md={2}>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Dealer</InputLabel>
                                    <Select
                                        id="dealerId"
                                        name="dealerId"
                                        value={dlid}
                                        onChange={(e) => {
                                            setDlid(e.target.value);
                                            setCid(0);
                                        }}
                                        fullWidth
                                    >
                                        {dealers.length > 1 && (
                                            <MenuItem key={0} value={0}>
                                                All dealers
                                            </MenuItem>
                                        )}
                                        {dealers.map((dealer, index) => (
                                            <MenuItem key={index} value={dealer.id}>
                                                {dealer.id}. {dealer.company}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridItem>
                        )}
                        <GridItem xs={12} sm={12} md={2}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-label">Customers</InputLabel>
                                <Select
                                    id="dealerId"
                                    name="dealerId"
                                    value={cid}
                                    onChange={(e) => {
                                        setCid(e.target.value);
                                    }}
                                    fullWidth
                                >
                                    <MenuItem key={0} value={0}>
                                        Select customer
                                    </MenuItem>

                                    {customers.map((customer, index) => (
                                        <MenuItem key={index} value={customer.id}>
                                            {customer.billCompany}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    id="date-picker-dialog"
                                    label="From date"
                                    format="MM/dd/yyyy"
                                    value={fromDate}
                                    onChange={(value) => setFromDate(value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </GridItem>
                        <GridItem xs={12} sm={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    id="date-picker-dialog"
                                    label="To date"
                                    format="MM/dd/yyyy"
                                    value={toDate}
                                    onChange={(value) => setToDate(value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </GridItem>
                        <GridItem xs={12} sm={2}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    id="statusId"
                                    name="statusId"
                                    value={statusId}
                                    onChange={(e) => {
                                        setStatusId(e.target.value);
                                    }}
                                    fullWidth
                                >
                                    <MenuItem key={0} value={0}>
                                        Select status
                                    </MenuItem>
                                    {["Pending", "Approved", "Denied", "Refunded"].map(
                                        (status, index) => (
                                            <MenuItem key={index + 1} value={status}>
                                                {status}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={2}>
                            <TextField
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                label="Search String"
                                helperText="[Name, Order# etc]"
                                fullWidth
                            />
                        </GridItem>
                        <GridItem xs={12} sm={2} style={{ display: "none" }}>
                            <Button
                                color="success"
                                variant="contained"
                                startIcon={<SearchIcon />}
                                onClick={() => {
                                    console.log(fromDate, toDate, status, search);
                                }}
                            >
                                Search
                            </Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginTop: 10 }}>
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                            <ButtonMUI
                                color="transparent"
                                variant="outlined"
                                onClick={() => {
                                    setDlid(0);
                                    setCid(0);
                                    setFromDate(null);
                                    setToDate(null);
                                    setStatusId("Pending");
                                    setSearch("");
                                }}
                            >
                                Clear filters
                            </ButtonMUI>
                        </GridItem>
                    </GridContainer>
                </div>

                {/*<GridContainer style={{ marginBottom: 10 }}>
                    <GridItem xs={12} sm={2}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel>Update rma status</InputLabel>
                            <Select
                                value={newStatusId}
                                onChange={(e) => {
                                    setNewStatusId(e.target.value);
                                }}
                            >
                                {["Pending", "Approved", "Denied", "Refunded"].map(
                                    (status, index) => (
                                        <MenuItem key={index + 1} value={status}>
                                            {status}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <DeleteButton
                            disabled={newStatusId === 0 || selected.length === 0}
                            variant="contained"
                            buttonWithIcon
                            startIcon={<SendIcon />}
                            buttonLabel="Apply"
                            customTitle="Confirm rma Status?"
                            customSubTitle="This will change the current rma status."
                            confirmationBtnTitle="Save"
                            handleClick={async () => {
                                for (var i = 0; i < selected.length; i++) {
                                    const rmaData = {
                                        id: selected[i],
                                        rma_dealer_status: newStatusId,
                                        rma_dealer_user: user?.completeName || "",
                                    };

                                    await updateEntity(rmaData, () => {});

                                    setOrders([...orders].filter((o) => !selected.includes(o.id)));
                                }
                            }}
                            style={{
                                marginTop: 10,
                                backgroundColor:
                                    newStatusId !== 0 && selected.length !== 0
                                        ? "#4caf50"
                                        : undefined,
                            }}
                        />
                    </GridItem>
                </GridContainer>*/}
            </Box>

            <Box mt={5}>
                <ReactTable
                    columns={columns}
                    data={orders}
                    fetchData={fetchData(fromDate, toDate, statusId, search, cid, dlid)}
                    pageCount={pageCount}
                    manualPagination={true}
                    showSorting={false}
                />
            </Box>
        </PageLayout>
    );
}
