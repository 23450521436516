import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SendIcon from "@material-ui/icons/Send";
import useMenu from "hooks/useMenu";
import useUser from "hooks/useUser";
import RequestFormModal from "components/RequestFormModal/RequestFormModal";
import useModal from "hooks/useModal";
import { SHOW_SUBMIT_REQUEST } from "services/Http";

const showSubmitRequest = SHOW_SUBMIT_REQUEST === "Y";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: "#333333",
    },
    white: {
        color: theme.palette.common.white,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const ListItem = withStyles({
    root: {
        borderRadius: 5,
        margin: "5px 0px 5px 0px",
        "& span": {
            fontSize: "14px",
        },
        "&$selected": {
            backgroundColor: "#ef662f",
            color: "white",
            "& .MuiListItemIcon-root": {
                color: "white",
            },
        },
        "&$selected:hover": {
            backgroundColor: "#ef662f",
            color: "white",
            "& .MuiListItemIcon-root": {
                color: "white",
            },
        },
        "&:hover": {
            backgroundColor: "#474747",
            color: "white",
            "& .MuiListItemIcon-root": {
                color: "white",
            },
        },
    },
    selected: {},
})(MuiListItem);

function CustomListItem(props) {
    const { classes, name, views = [], layout, path, createLinks = () => {}, history } = props;
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        if (views.length > 0) setOpen(!open);
        else history.push(`${layout}${path}`);
    };

    const isSelected = history.location.pathname === `${layout}${path}`;

    return (
        <>
            <ListItem
                button
                onClick={handleClick}
                style={{ paddingLeft: 10 }}
                selected={isSelected}
            >
                {/*<ListItemIcon style={{ minWidth: 35 }}>
                    <props.icon className={classes.white} />
                </ListItemIcon>*/}
                <ListItemText primary={name} />
                {views.length > 0 ? open ? <ExpandLess /> : <ExpandMore /> : null}
            </ListItem>
            {views.length > 0 ? (
                <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    style={{ paddingLeft: 30, marginRight: 10 }}
                >
                    <List component="div" disablePadding>
                        {createLinks(views)}
                    </List>
                </Collapse>
            ) : null}
        </>
    );
}

const ASSIGNED_ROLES_ONLY = true;

export default function CustomSidebar() {
    const classes = useStyles();
    const history = useHistory();
    const [user] = useUser();
    const { treeMenu = [] } = useMenu(ASSIGNED_ROLES_ONLY);
    const { open, handleOpenModal, handleCloseModal } = useModal();

    function createLinks(menu = []) {
        return menu.map((submenu, index) => (
            <CustomListItem
                key={index}
                index={index}
                classes={classes}
                icon={submenu.icon}
                name={submenu.name}
                views={submenu.views}
                layout={submenu.layout}
                path={submenu.path}
                createLinks={createLinks}
                history={history}
            />
        ));
    }

    return (
        <div className={classes.root} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <List component="nav">
                {createLinks(treeMenu)}
                <RequestFormModal open={open} handleCloseModal={handleCloseModal} />
                {user && user.isAdmin === 0 && showSubmitRequest && (
                    <ListItem
                        button
                        onClick={handleOpenModal}
                        style={{ paddingLeft: 10 }}
                        selected={false}
                    >
                        <ListItemIcon style={{ minWidth: 35 }}>
                            <SendIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Submit Request"} />
                    </ListItem>
                )}
            </List>
        </div>
    );
}
