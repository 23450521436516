import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// const
import { CREATE_USER, EDIT_USER } from "./Users";

//services
import UserService from "../../services/UserService";
import RoleService from "../../services/RoleService";
import UserRoleService from "../../services/UserRoleService";
import useFetchDealerDomains from "hooks/useFetchDealerDomains";

const validationSchema = yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().required("Email is required").email("Enter a valid email"),
});

const useStyles = makeStyles({
    updateProfileButton: {
        float: "right",
    },
});

export default function CreateUser({ form = CREATE_USER, initialValues, onSuccess }) {
    const [roles, setRoles] = useState([]);
    const classes = useStyles();
    const { dealerDomains = [] } = useFetchDealerDomains();

    useEffect(() => {
        const getRoles = async () => {
            const roles = await RoleService.getAll();
            setRoles(roles.filter((r) => r.roleType === "smartdealer"));
        };
        getRoles();
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || 0,
            firstName: initialValues.firstName || "",
            lastName: initialValues.lastName || "",
            email: initialValues.email || "",
            password: initialValues.password || "",
            roleId: initialValues.roleId || "",
            userOrigin: "users",
            managedDomains: initialValues.managedDomains || [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (form === CREATE_USER) createUser(values);
            if (form === EDIT_USER) updateUser(values);
        },
    });

    const createUser = async (body) => {
        try {
            const user = await UserService.post(body);
            await UserRoleService.post({
                userId: user.data.id,
                roleId: user.data.roleId,
                userOrigin: user.data.userOrigin,
            });
            onSuccess({
                ...user.data,
                role: roles.find((r) => r.id === user.data.roleId)?.name || "",
            });
        } catch (error) {
            if (error.response && error.response.status === 409) {
                formik.setFieldError("email", "User already exists. Try another email.");
            } else {
                console.error(error);
            }
        }
    };

    const updateUser = async (body) => {
        try {
            const user = await UserService.put(body);
            await UserRoleService.put({
                userId: user.data.id,
                roleId: user.data.roleId,
            });
            onSuccess({
                ...user.data,
                role: roles.find((r) => r.id === user.data.roleId)?.name || "",
            });
        } catch (error) {
            if (error.response && error.response.status === 409) {
                formik.setFieldError("email", "User already exists. Try another email.");
            } else {
                console.error(error);
            }
        }
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="firstName"
                                name="firstName"
                                label="First name"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="lastName"
                                name="lastName"
                                label="Last name"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="email"
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </GridItem>
                        {form === CREATE_USER && (
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    type="password"
                                    autoFocus
                                    fullWidth
                                    margin="dense"
                                    id="password"
                                    name="password"
                                    label="Password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.password && Boolean(formik.errors.password)
                                    }
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                            </GridItem>
                        )}
                        <GridItem xs={12} sm={12} md={6}>
                            <FormControl variant="standard" fullWidth style={{ marginTop: 5 }}>
                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                <Select
                                    id="roleId"
                                    name="roleId"
                                    value={formik.values.roleId}
                                    onChange={(e) => {
                                        formik.setFieldValue("roleId", e.target.value);
                                    }}
                                    fullWidth
                                >
                                    <MenuItem key={0} value={0}>
                                        Select role
                                    </MenuItem>
                                    {roles.map(({ id, name }, index) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridItem>

                        {formik.values.roleId &&
                        roles.some((r) => r.id === formik.values.roleId) &&
                        roles.find((r) => r.id === formik.values.roleId).isMultiDomain === "Y" ? (
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    select
                                    fullWidth
                                    margin="dense"
                                    id="managedDomains"
                                    name="managedDomains"
                                    label="Domains to Manage"
                                    value={formik.values.managedDomains}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.managedDomains &&
                                        Boolean(formik.errors.managedDomains)
                                    }
                                    helperText={
                                        formik.touched.managedDomains &&
                                        formik.errors.managedDomains
                                    }
                                    SelectProps={{
                                        multiple: true,
                                    }}
                                >
                                    {dealerDomains
                                        .map((domain) => ({
                                            id: domain,
                                            value: `*.${domain}`,
                                        }))
                                        .map((domain) => (
                                            <MenuItem key={domain.id} value={domain.id}>
                                                {domain.value}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </GridItem>
                        ) : null}
                    </GridContainer>
                    <Button type="submit" color="rose" className={classes.updateProfileButton}>
                        Save
                    </Button>
                </form>
            </GridItem>
        </GridContainer>
    );
}
