import axios from "axios";
import { getUser } from "helpers";

// Obtén información del usuario
const { id = 0, firstName = "", lastName = "" } = getUser() || {};
const userName = `${firstName} ${lastName}`;
const dealerId = parseInt(localStorage.getItem("_dlid") || "0");

// Definición de variables usando window.config
const config = window.config || {}; // Carga las variables desde config.js

export const TEST = config.REACT_APP_TEST;

export const BASE_URL_API = config.REACT_APP_BASE_URL_API;
export const BASE_URL_API_ECOMMERCE = config.REACT_APP_BASE_URL_API_ECOMMERCE;
export const BASE_URL_FRONTEND_PORT = config.REACT_APP_BASE_URL_FRONTEND_PORT;
export const BASE_URL_FRONTEND = config.REACT_APP_BASE_URL_FRONTEND;
export const BASIC_AUTH = config.REACT_APP_BASIC_AUTH;
export const BASE_URL_TRANSLATION = config.REACT_APP_BASE_URL_TRANSLATION;
export const FOOTER_COPYRIGHT = config.REACT_APP_FOOTER_COPYRIGHT;
export const SHOW_SUBMIT_REQUEST = (config.REACT_APP_SHOW_SUBMIT_REQUEST ?? "Y").toUpperCase();

// Configuración de Axios
export default axios.create({
    baseURL: BASE_URL_API,
    headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${BASIC_AUTH}`,
        DealerId: dealerId,
        UserId: id,
        UserName: userName,
    },
});
