import http, { BASE_URL_API_ECOMMERCE } from "./Http";

const fetchMarketplaceData = async ({
    dealerId = "",
    region = "USA", // 1 = USA, 2 = CAN
    catalogs = [],
    loc = "en",
    from = null,
    to = null,
    platform = null,
    vehicles = [],
}) => {
    const queryString = new URLSearchParams({
        dealerId,
        region,
        catalogs: catalogs.join(","),
        loc,
        from,
        to,
        platform,
    }).toString();

    const res = await http.post(
        `${BASE_URL_API_ECOMMERCE.replace("/api", "")}/marketplace-export?${queryString}`,
        { vehicles }
    );

    return res.data.data;
};

const generateMLExcel = async ({ dealerId, products = [] }) => {
    return http.post(`/meli-excel-exporter`, { dealerId, products });
};

const deleteMLExcel = async ({ dealerId, fileName }) => {
    return http.post(`/meli-excel-exporter/delete`, { dealerId, fileName });
};

const fetchInventoryData = async ({
    skus = [],
    minStockLevel = null,
    dimWeightFrom = null,
    dimWeightTo = null,
}) => {
    const res = await http.post(`/inventory-availability`, {
        skus,
        minStockLevel,
        dimWeightFrom,
        dimWeightTo,
    });
    return res.data.data;
};

const fetchInventoryObservations = async ({
    skus = [],
    minStockLevel = null,
    dimWeightFrom = null,
    dimWeightTo = null,
}) => {
    const res = await http.post(`/inventory-observations`, {
        skus,
        minStockLevel,
        dimWeightFrom,
        dimWeightTo,
    });
    return res.data.data;
};

const ExportService = {
    fetchMarketplaceData,
    generateMLExcel,
    deleteMLExcel,
    fetchInventoryData,
    fetchInventoryObservations,
};

export default ExportService;
