import { getUser } from "helpers";
import { useState, useEffect } from "react";
import DealerService from "services/DealerService";
import UserService from "services/UserService";

const FILTER_DEALERS = 1;

const useDealers = () => {
    const [dealers, setDealers] = useState([]);
    const sdlid = parseInt(localStorage.getItem("_sdlid"));
    const dlid = parseInt(localStorage.getItem("_dlid"));
    const { id: dealerUserId } = getUser(sdlid) || {};

    useEffect(() => {
        const getDealers = async () => {
            try {
                let managedDomains = "";

                if (dealerUserId) {
                    const response = await UserService.get(dealerUserId);

                    if (response && response.data?.managedDomains) {
                        try {
                            managedDomains = JSON.parse(response.data.managedDomains);
                        } catch (error) {
                            console.error("Error parsing managedDomains:", error);
                            managedDomains = [];
                        }
                    }
                }

                if (Array.isArray(managedDomains)) {
                    managedDomains = managedDomains.join(",");
                }

                const parsedDlid = sdlid === FILTER_DEALERS ? dlid : "";
                const dealers = await DealerService.getAll(parsedDlid, managedDomains).then(
                    (o) => o.data
                );

                setDealers(dealers);
            } catch (error) {
                console.error("Error fetching dealer user or dealers:", error);
            }
        };

        getDealers();
    }, [sdlid, dealerUserId]);

    return dealers;
};

export default useDealers;
