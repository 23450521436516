import useSWR from "swr";
import DealerService from "services/DealerService";

const fetchDealerDomains = async () => {
    const response = await DealerService.getDealerDomains();
    return response.data;
};

export default function useFetchDealerDomains() {
    const { data: dealerDomains, error, isLoading } = useSWR("dealer-domains", fetchDealerDomains);

    return {
        dealerDomains,
        isLoading,
        error,
    };
}
